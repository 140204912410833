import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutFr = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ludo: file(relativePath: { eq: "ludo.jpg" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lacuzon: file(relativePath: { eq: "lacuzon.jpg" }) {
        childImageSharp {
          fixed(width: 196) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const [isFolded, setIsFolded] = useState(true)
  return (
    <Layout location={location}>
      <SEO
        title="Qui suis-je"
        description="Ludovic Guyot, animateur touristique et musical"
      />
      <div className="columns is-centered">
        <div className="column is-3 has-text-centered pt-5">
          <Img
            fixed={data.ludo.childImageSharp.fixed}
            alt="Ludovic Guyot"
            style={{ maxWidth: 260, width: "100%", borderRadius: 20 }}
          />
        </div>
        <div className="column">
          <p>
            Je m’appelle Ludovic GUYOT et je suis né à Lons-le-Saunier dans le
            Jura.
          </p>
          <p>
            J'y réside avec plaisir depuis ce jour, mais je le découvre encore
            au fil de mes randonnées et de la pratique du geocaching.
          </p>
          <h3>Mon parcours professionnel</h3>
          <p>
            J’ai eu très vite le goût de l’Histoire, ce qui m’a conduit à passer
            une Licence dans cette spécialité lors de mes études à Besançon.
          </p>
          <p>
            J’ai entamé ma vie professionnelle en m’occupant du Devoir de
            Mémoire au sein de l’Office National des Anciens Combattants pour
            être un acteur et un vecteur de liens entre les générations. J'ai
            également eu plusieurs expériences en Office de Tourisme dans la
            région.
          </p>
          <h3>Mes goûts</h3>
          <p>
            J’aime transmettre ce que je sais et je suis quelqu’un de curieux
            au-delà de mes sentiers battus. J’aime les rencontres car elles sont
            enrichissantes, car l’on apprend souvent des uns et des autres.
            J’aime découvrir de nouveaux chemins, de nouveaux sentiers, de
            nouvelles routes dans la nature et la variété des paysages. J'aime
            l'histoire et le patrimoine de ma région et ses petites citées
            comtoises de caractère. J’aime partager et faire connaître les bons
            produits du terroir jurassien.
          </p>
          <p>Au plaisir, de voyager avec vous !</p>
          <p>Ludovic</p>
          <p>Lacuzon Loisirs</p>
        </div>
      </div>

      <div className="columns is-vcentered">
        <div className="column is-3 has-text-centered">
          <Img
            fixed={data.lacuzon.childImageSharp.fixed}
            alt="Lacuzon"
            style={{ maxWidth: 196, width: "100%" }}
          />
        </div>
        <div className="column">
          <h3>
            et Pourquoi Lacuzon?
            <br />
            un peu d'histoire...
          </h3>
          <p>
            C’est en 1674 que la Franche-Comté (Jura, Doubs, Haute-Saône et
            territoire de Belfort) fut rattachée à la France, après 39 années de
            guerre dont Lacuzon fut le héros.
          </p>
          <p>
            Claude Prost, dit Lacuzon, est né à Longchaumois en 1607 de parents
            cultivateurs et, n’ayant pas la vocation paysanne, il descendit à
            Saint-Claude où il exerça (peut-être ?) le métier de cordonnier.
            Marié à Jeanne Blanc le 31 octobre 1632 avec qui il eut 2 filles, il
            semblait conduit à une vie rangée de petit artisan à l’ombre de la
            célèbre abbaye sanclaudienne.
          </p>
          <h4>1636 : Sa destinée bascule</h4>
          <p>
            Le cours de sa vie changea avec la guerre que Richelieu, alors
            ministre de Louis XIII, déclenche contre la Maison d’Autriche pour
            annexer la Franche-Comté. Lorsque Dole est assiégée par 18000
            hommes, sous la conduite du Grand Condé, Claude Prost est mobilisé
            avec une troupe de Sanclaudiens et son ascendant sur les soldats ne
            va pas cesser de grandir. Hardi mais prudent, il est bientôt
            surnommé « La Cuson », en patois « le souci ».
          </p>
          {isFolded ? (
            <div className="fake-a" onClick={() => setIsFolded(false)} role="button" tabIndex={0} onKeyDown={() => setIsFolded(false)}>
              Lire la suite...
            </div>
          ) : (
            <>
              <h4>1636-1642 : Une période abominable</h4>
              <p>
                La Franche-Comté est presque totalement occupée par les Français
                aidés des mercenaires suédois. Seules Dole, Salins, Besançon et
                Gray résistent. C’est la période la plus terrible qu’on puisse
                imaginer. Les « gris » ravagent, pillent, incendient, tuent et
                commettent les pires horreurs contre ceux qui leur résistent. De
                plus, la famine et la peste déciment la population et le pays
                semble devenir un désert. C’est à cette époque que la carrière
                militaire de Lacuzon prend son envol. Il mène une lutte
                implacable contre les « gris » de Lespinassou dans la région de
                Lons et de Bletterans.
              </p>
            </>
          )}
        </div>
      </div>
      {isFolded ? null : (
        <>
          <h4>Lacuzon « prend du galon »</h4>
          <p>
            En 1637, il rallie « la terce », une unité régulière de 1000
            cavaliers et 2000 fantassins commandée par le baron d’Arnans. Ce
            dernier délègue alors à Lacuzon la garde d’un poste de grande
            importance stratégique commandant l’accès de la vallée de la Bienne
            à Vaux-les-Saint-Claude.
          </p>
          <p>
            Mais en 1639, la situation est des plus critiques : les Comtois sont
            submergés et ne tiennent plus que quelques places fortes, dans le
            secteur montagneux surtout. Ils ne peuvent ainsi empêcher le sac et
            l’incendie de Saint-Claude. Pourtant, l’ennemi se retire rapidement
            de ces rudes montagnes du Haut-Jura trop difficiles à tenir en
            occupation et… Lacuzon reprend le contrôle de la situation. Son rôle
            alors terminé, d’Arnans quitte la Franche-Comté, tout en faisant
            octroyer à Lacuzon le commandement du château de Montaigu.
          </p>
          <h4>Le château de Montaigu devient sa résidence</h4>
          <p>
            1642 : Lacuzon s’est installé dans les ruines de son nouveau
            château, véritable nid d’aigle surplombant Lons-le-Saunier et la
            plaine occupées. Bien remise en état, sa forteresse semble
            imprenable par l’ennemi, ce qui lui vaut de chaudes félicitations du
            gouverneur et du Parlement de Dole.
          </p>
          <p>
            Pendant de longues années, jusqu’à la conclusion du traité de paix
            des Pyrénées de novembre 1659 qui confirme l’appartenance de la
            Comté à la Maison d’Espagne, la situation demeure confuse. Quelques
            raids et coups de main ravagent surtout les régions frontières ; en
            1644, Turenne s’empare de Baume-les-Dames, Vesoul et Luxeuil.
          </p>
          <p>
            Pour Lacuzon, ce sera une grande époque de sa carrière. De Montaigu,
            où il s’est installé avec femme et chapelain, il ne cesse de
            harceler les communications de l’ennemi. A cette époque il s’empare
            du château fortifié de Saint-Laurent la Roche qui commande l’accès
            du Revermont et qui était occupé par les Français depuis 1637. La
            prise de cette place forte a un grand retentissement politique en
            Franche-Comté et, en 1642, le Roi d’Espagne accorde officiellement à
            Lacuzon la capitainerie et le commandement de ce château.
          </p>
          <h4>Un véritable seigneur</h4>
          <p>
            S’étant beaucoup enrichi, notre héros franc-comtois vit sur le pays
            comme il est alors admis à l’époque, pillant les convois ennemis et
            libérant contre de fortes rançons ses nobles prisonniers…
          </p>
          <p>
            En 1643, âgé de 36 ans et après 7 années de guerre, il a une
            situation exceptionnelle. Parti simple soldat, il est maintenant
            gouverneur des châteaux de Montaigu, Saint-Laurent et Arlay. Sa
            présence derrière les murs fortifiés rassure la population qui
            reprend courage, mais ses activités militaires se limitent de plus
            en plus à la répression des pillages et abus commis par des bandes
            de soldats déserteurs.
          </p>
          <p>
            Par contre, sa générosité reste intacte et il la manifeste parfois
            avec ostentation, faisant par exemple un don considérable de 500 F à
            Saint-Claude pour la remise en état d’une chapelle brûlée par les «
            gris », y ajoutant 100 écus pour une fondation de messe perpétuelle…
            C’est à cette époque aussi qu’il achète, à Montaigu, une importante
            propriété où il fait construire une belle maison et, en 1651, il s’y
            installe définitivement avec sa femme et ses deux filles.
          </p>
          <h4>Lacuzon n’est pas toujours irréprochable…</h4>
          <p>
            Pendant toutes ces années, il règne un peu en satrape oriental sur
            ses capitaineries et il lui arrive, hélas de commettre de nombreux
            abus… Naturellement « galant », il n’hésite pas à violenter les
            femmes qui lui résistent : bref, il se crée alors de nombreux
            ennemis. Des plaintes sont portées contre lui au Parlement de Dole
            et il est arrêté, emprisonné quelque temps à la conciergerie. On lui
            confisque son château de Saint-Laurent, son procès est instruit et
            suivi avec passion et les plaintes, graves ou futiles, s’accumulent.
            Mais Lacuzon a des amis dévoués et on se souvient en particulier des
            services rendus…
          </p>
          <p>
            Finalement, le 22 novembre 1659, il est absous par le Roi d’Espagne,
            par le Parlement de Dole et acclamé par le sentiment populaire.
            Saint-Laurent la Roche lui est restitué. Et comme le traité de paix
            des Pyrénées vient d’être signé (le 7 novembre), les Français
            évacuent les territoires qu’ils occupent.
          </p>
          <h4>La paix ne sera pas durable</h4>
          <p>
            Louis XIV, roi de France depuis 1643, n’a pas renoncé à cette
            province de l’Espagne, mal gouvernée par la reine Marie-Anne
            d’Autriche. Celle-ci ne fait rien pour renforcer les défenses ni
            galvaniser le patriotisme de ses habitants. Emoussés par 24 années
            de luttes épuisantes, les Franc-Comtois sont de plus en plus
            divisés. Un fort parti de la noblesse penche de plus en plus du côté
            de la France et Lacuzon a du mal à obtenir munitions, soldats et
            subsides.
          </p>
          <p>
            En 1668, Condé entre en Franche-Comté avec 20 000 hommes et s’empare
            de Besançon, Salins, Dole et Gray. Toutes les autorités sont prêtes
            à prêter serment à Louis XIV.
          </p>
          <p>
            Lacuzon est sommé par le nouveau gouverneur français de remettre son
            château de St-Laurent aux troupes royales et de venir faire sa «
            soumission officielle ». Il prête serment, revient à St-Claude, prêt
            à passer en Suisse si les Français lui voulaient du mal. Ces
            derniers détruisent de fond en comble ses châteaux de Montaigu et de
            St-Laurent.
          </p>
          <h4>Nouveau coup de théâtre</h4>
          <p>
            Le 2 mai 1668, le nouveau traité de paix signé à Aix-le-Chapelle
            restitue, une fois de plus, la Franche-Comté à l’Espagne, mais c’est
            désormais une province divisée entre « résistants » et «
            collaborateurs » que notre héros franc-comtois doit défendre.
          </p>
          <p>
            La Cour d’Espagne envoie alors un gouverneur flamand, le Prince
            d’Arenberg, qui fait immédiatement appel à …Lacuzon dont il a appris
            la fidélité et les capacités. Il lui confie le commandement d’un
            baillage dont Lons est le centre, avec des pouvoirs quasi absolus.
          </p>
          <p>
            Pendant six nouvelles années, Lacuzon va jouir de la confiance
            totale et méritée du gouverneur espagnol. Il s’efforce de vaincre
            l’apathie, voire l’hostilité de la population, tente de reprendre la
            lutte, et doit venir à bout d’un certain marquis de Listenois.
            Celui-ci, à la tête d’une importante faction de la noblesse
            comtoise, ne pourra pourtant venir à bout de Lacuzon, s’enfuit
            finalement et se réfugie en France.
          </p>
          <h4>Derniers soubresauts d’une guerre qui n’a que trop duré</h4>
          <p>
            1673 : La guerre n’est pas loin et le Roi Soleil sait bien qu’il
            peut compter désormais sur de nombreux partisans. Il envoie une
            armée considérable et fait capituler Besançon. Le 4 juin 1674, la
            ville de Salins est, elle aussi, investie par les troupes du Duc de
            Luxembourg : ce sera l’ultime bataille qui clôturera la conquête
            définitive de la Franche-Comté. Lacuzon tente désespérément de
            résister encore mais, sous la pression des notables salinois, une
            trêve est conclue.
          </p>
          <p>
            La Franche-Comté est aux abois, mais elle a fait payer cher au Roi
            de France sa conquête finale, et son honneur est sauf…
          </p>
          <p>
            Lacuzon aurait pu se retirer tranquillement à Montaigu. Mais, à 67
            ans, il ne peut se résigner à voir le drapeau « fleur de lys »
            flotter sur son Haut-Jura et à devenir la cible désarmée des
            rancunes accumulées contre lui…
          </p>
          <p>
            Il décide de se réfugier à Milan, citadelle espagnole. Il fait ses
            adieux à sa famille qu’il laisse à Montaigu ou à Saint-Claude et
            rédige son testament. Il se sépare de Denise Gobet, « sa jeune et
            accorte gouvernante » qui lui sert de « femme de campagne ». Et, 9
            mois après, celle-ci déclarera la naissance illégitime d’un fils
            dont elle attribue la paternité à Lacuzon…
          </p>
          <p>
            En 1678, arrivé à Milan, il reprend la lutte en dirigeant une
            expédition contre les Siciliens révoltés. Et c’est le 17 septembre
            de cette même année que La Paix de Nimègue consacre l’annexion de la
            Franche-Comté et rétablit la paix entre la France et l’Espagne:
            Lacuzon peut rentrer librement dans son pays natal.
          </p>
          <h4>Les derniers jours de Claude Prost</h4>
          <p>
            Lacuzon, alors âgé de 72 ans, revient dans le Jura à la fin de 1679.
            Sa femme y est morte, ainsi que Anne-Marie, sa fille préférée. De
            plus, l’attitude de son gendre, qui s’est rallié avec sincérité à la
            France, lui semble une forfaiture. Il refuse donc de rester dans
            cette Franche-Comté qu’il considère toujours comme injustement
            occupée et décide de retourner à Milan.
          </p>
          <p>
            Il marque un temps d’arrêt à Longchaumois , son village natal, puis
            quitte définitivement le Jura. Il meurt à Milan le 21 décembre 1681,
            entouré de ses camarades d’exil.
          </p>
          <p>
            Telle fut la destinée du Capitaine Lacuzon. La légende s’en est
            emparée, l’a embellie, déformée, et le Lacuzon du célèbre roman de
            cape et d’épée de Xavier de Montépin « Le Médecin des Pauvres » n’a
            pas grand chose de commun avec le brave soldat loyal et malheureux
            qu’il fut réellement. Mais Lacuzon est un nom qui résonne en
            Franche-Comté comme l’écho fidèle de la fameuse devise :
          </p>
          <p>« COMTOIS RENDS-TOI : NENNI MA FOI »</p>
          <h4>Bibliographie :</h4>
          <p>LACUSON de FONVILLE</p>
          <p>LACUSON de LAUTREY</p>
          <p>
            L'Histoire de la Terre et de l'Abbaye de Saint-CLaude de Don BENOIT
          </p>
        </>
      )}
    </Layout>
  )
}

export default AboutFr
